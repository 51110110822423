import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import { Link } from 'gatsby';
import PlayerContext from '../services/player';

const IndexPage = () => (
  <Layout>
    <PlayerContext>
      <Header />
      <header className="masthead">
        <div className="container d-flex h-100 align-items-center">
          <div className="mx-auto text-center">
            <h1 className="mx-auto my-0 text-uppercase">Page not found</h1>
            <h2 className="text-white-50 mx-auto mt-2 mb-5">Not a valid URL</h2>

            <Link to="/" className="btn btn-primary">
              Go Home
            </Link>
          </div>
        </div>
      </header>
    </PlayerContext>
  </Layout>
);

export default IndexPage;
